import './HomeS6.scss';

import React, { useState } from 'react';

import Col from '../../../components/Col/Col';
import Heading from '../../../components/Heading/Heading';
import Image from '../../../components/Image/Image';
import RollButLast from '../../../components/RollButLast/RollButLast';
import Row from '../../../components/Row/Row';
import ScrollTrigger from '../../../components/ScrollTrigger/ScrollTrigger';
import Section from '../../../components/Section/Section';

const HomeS6 = ({ headIMG = {}, tableRows = [] }) => {
  if (!headIMG.fluid && !tableRows.length) {
    return null;
  }

  const [isTextVisible, setIsTextVisible] = useState(false);

  const handleTextScrollEnter = () => {
    setIsTextVisible(true);
  };

  return (
    <Section className="home-s6">
      <Row>
        <Col xx={12} xs={8} md={4} mdOffset={1} xl={5}>
          <ScrollTrigger>{headIMG.fluid && <Image {...headIMG} />}</ScrollTrigger>
        </Col>
        <Col xx={12} xs={10} xsOffset={2} md={6} mdOffset={1} xl={4} className="home-s6--right">
          <ScrollTrigger allSet={{ to: { scrollTrigger: { onEnter: handleTextScrollEnter } } }}>
            <RollButLast start={isTextVisible}>
              {tableRows.map((row) => (
                <Heading key={row.text}>
                  <h1>
                    <span>BAD </span>
                    {row.text}
                  </h1>
                </Heading>
              ))}
            </RollButLast>
          </ScrollTrigger>
        </Col>
      </Row>
    </Section>
  );
};

export default HomeS6;
