import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';
import React from 'react';

import HomeHero from './HomeHero/HomeHero';
import HomeS1 from './HomeS1/HomeS1';
import HomeS2 from './HomeS2/HomeS2';
import HomeS3 from './HomeS3/HomeS3';
import HomeS4 from './HomeS4/HomeS4';
import HomeS5 from './HomeS5/HomeS5';
import HomeS6 from './HomeS6/HomeS6';
import HomeS7 from './HomeS7/HomeS7';

const HomePage = ({
  data: {
    page: {
      data: {
        hero_opening_text,
        section_1_desc,
        section_1_image,
        section_2_image,
        section_2_link_text,
        section_2_table_header_text,
        section_2_table_rows,
        section_2_title,
        section_3_slides,
        section_3_title,
        section_4_link_text,
        section_4_partners,
        section_4_title,
        section_5_image,
        section_5_table_rows,
        section_5_title,
        section_6_image,
        section_6_bad_texts,
        section_7_desc,
        section_7_image,
        section_7_link_text,
        section_7_title,
      } = {},
    } = {},
  } = {},
  pageContext: { routes = {} } = {},
}) => {
  const scrollDownTargetId = 'homeScrollDownTarget';

  return (
    <React.Fragment>
      {/* Hero */}
      <HomeHero openingRT={hero_opening_text} scrollDownTargetId={scrollDownTargetId} />

      {/* Section 1 */}
      <HomeS1 headingRT={section_1_desc} headIMG={section_1_image} scrollDownTargetId={scrollDownTargetId} />

      {/* Section 2 */}
      <HomeS2
        headIMG={section_2_image}
        headingRT={section_2_title}
        linkText={section_2_link_text}
        linkTo={routes.ourProcess.prefix}
        tableHeaderRT={section_2_table_header_text}
        tableRows={section_2_table_rows}
      />

      {/* Section 3 */}
      <HomeS3 headingRT={section_3_title} slides={section_3_slides} />

      {/* Section 4 */}
      <HomeS4
        headingRT={section_4_title}
        linkText={section_4_link_text}
        linkTo={routes.ourWork.prefix}
        partners={section_4_partners}
      />

      {/* Section 5 */}
      <HomeS5 headIMG={section_5_image} headingRT={section_5_title} tableRows={section_5_table_rows} />

      {/* Section 7 */}
      <HomeS6 headIMG={section_6_image} tableRows={section_6_bad_texts} />

      {/* Section 7 */}
      <HomeS7
        descRT={section_7_desc}
        headIMG={section_7_image}
        headingRT={section_7_title}
        linkText={section_7_link_text}
        linkTo={routes.ourTeam.prefix}
      />
    </React.Fragment>
  );
};

export default withPreview(HomePage);

export const query = graphql`
  query($lang: String) {
    page: prismicHomePage(lang: { eq: $lang }) {
      data {
        hero_opening_text {
          raw
          text
        }

        section_1_desc {
          raw
          text
        }
        section_1_image {
          alt
          fluid(maxWidth: 760) {
            ...GatsbyPrismicImageFluid_withWebp
          }
        }

        section_2_image {
          alt
          fluid(maxWidth: 550) {
            ...GatsbyPrismicImageFluid_withWebp
          }
        }
        section_2_link_text
        section_2_table_header_text {
          raw
          text
        }
        section_2_table_rows {
          text {
            raw
            text
          }
        }
        section_2_title {
          raw
          text
        }

        section_3_slides {
          color
          icon {
            alt
            fluid(maxWidth: 280) {
              ...GatsbyPrismicImageFluid_withWebp
            }
          }
          text {
            raw
            text
          }
          title
        }
        section_3_title {
          raw
          text
        }

        section_4_link_text
        section_4_partners {
          partner {
            document {
              ... on PrismicPartner {
                id
                data {
                  logo {
                    alt
                    url
                  }
                  name
                  short_desc {
                    text
                    raw
                  }
                }
              }
            }
          }
        }
        section_4_title {
          text
          raw
        }

        section_5_image {
          alt
          fluid(maxWidth: 410) {
            ...GatsbyPrismicImageFluid_withWebp
          }
        }
        section_5_table_rows {
          text {
            raw
            text
          }
          value
          value_type
        }
        section_5_title {
          raw
          text
        }

        section_6_bad_texts {
          text
        }
        section_6_image {
          alt
          fluid(maxWidth: 520) {
            ...GatsbyPrismicImageFluid_withWebp
          }
        }

        section_7_desc {
          raw
          text
        }
        section_7_image {
          alt
          fluid(maxWidth: 640) {
            ...GatsbyPrismicImageFluid_withWebp
          }
        }
        section_7_link_text
        section_7_title {
          raw
          text
        }
      }
    }
  }
`;
