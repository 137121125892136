import './HomeHero.scss';

import gsap from 'gsap';
import { RichText } from 'prismic-reactjs';
import React, { useEffect, useRef } from 'react';

import Heading from '../../../components/Heading/Heading';
import ScrollDown from '../../../components/ScrollDown/ScrollDown';
import Section from '../../../components/Section/Section';
import WeAreBad from '../../../components/WeAreBad/WeAreBad';

const HomeHero = ({ openingRT = {}, scrollDownTargetId }) => {
  const layer1Elem = useRef(null);
  const layer2Elem = useRef(null);

  useEffect(() => {
    if (!layer1Elem || !layer2Elem) {
      return () => {};
    }

    const tl = gsap.timeline();
    tl.addLabel('start')
      .to(layer1Elem.current, {
        delay: 0.5,
        duration: 1,
        ease: 'sine.in',
        onComplete: () => {
          layer1Elem.current.classList.remove('home-hero__layer--hidden');
        },
        opacity: 1,
        visibility: 'visible',
      })
      .to(layer1Elem.current, {
        delay: 3.5,
        duration: 1,
        ease: 'sine.out',
        opacity: 0,
      })
      .to(layer2Elem.current, {
        duration: 1,
        ease: 'sine.in',
        onComplete: () => {
          layer2Elem.current.classList.remove('home-hero__layer--hidden');
        },
        opacity: 1,
        visibility: 'visible',
      })
      .to(layer2Elem.current, {
        delay: 2.5,
        duration: 1,
        ease: 'sine.out',
        opacity: 0,
      })
      .repeat(-1);

    return () => {
      tl.kill();
    };
  }, [layer1Elem, layer2Elem]);

  return (
    <Section className="home-hero" isFullHeight isNoSpaced>
      <div className="home-hero__layer home-hero__layer--hidden home-hero__layer--1" ref={layer1Elem}>
        <Heading>
          <RichText render={openingRT.raw} />
        </Heading>
      </div>
      <div className="home-hero__layer home-hero__layer--hidden home-hero__layer--2" ref={layer2Elem}>
        <WeAreBad />
      </div>
      <ScrollDown targetId={scrollDownTargetId} />
    </Section>
  );
};

export default HomeHero;
