import './PartnerBox.scss';

import cx from 'classnames';
import { RichText } from 'prismic-reactjs';
import React from 'react';

import Text from '../Text/Text';

const PartnerBox = ({ descRT = {}, inactive, name, logoAlt, logoUrl }) => {
  const rootCx = cx('partner-box', {
    'partner-box--inactive': inactive,
  });

  return (
    <div className={rootCx}>
      <div className="partner-box__icon">
        <img src={logoUrl} alt={logoAlt} />
      </div>
      <Text className="partner-box__name">{name}</Text>
      {descRT.text && (
        <Text className="partner-box__desc">
          <RichText render={descRT.raw} />
        </Text>
      )}
    </div>
  );
};

export default PartnerBox;
