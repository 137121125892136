import './HomeS5.scss';

import { RichText } from 'prismic-reactjs';
import React, { useState } from 'react';

import Col from '../../../components/Col/Col';
import Counter from '../../../components/Counter/Counter';
import Heading from '../../../components/Heading/Heading';
import Image from '../../../components/Image/Image';
import Row from '../../../components/Row/Row';
import ScrollTrigger from '../../../components/ScrollTrigger/ScrollTrigger';
import Section from '../../../components/Section/Section';
import Table from '../../../components/Table/Table';
import TableRow from '../../../components/Table/TableRow';
import Text from '../../../components/Text/Text';

const HomeS5 = ({ headIMG = {}, headingRT = {}, tableRows = [] }) => {
  if (!headIMG.fluid && !tableRows.length) {
    return null;
  }

  const [isTableVisible, setIsTableVisible] = useState(false);

  const handleTableScrollEnter = () => {
    setIsTableVisible(true);
  };

  return (
    <Section className="home-s5">
      <Row>
        <Col xx={12} md={4} mdOffset={1}>
          <ScrollTrigger>{headIMG.fluid && <Image {...headIMG} />}</ScrollTrigger>
        </Col>
        <Col xx={12} md={6} mdOffset={1}>
          <Row>
            <Col xx={12}>
              <ScrollTrigger>
                {headingRT.text && (
                  <Heading>
                    <RichText render={headingRT.raw} />
                  </Heading>
                )}
              </ScrollTrigger>
            </Col>
            {tableRows.length > 0 && (
              <Col xx={9} xxOffset={3} md={12} mdOffset="off">
                <ScrollTrigger allSet={{ to: { scrollTrigger: { onEnter: handleTableScrollEnter } } }}>
                  {tableRows.length > 0 && (
                    <Table>
                      {tableRows.map((row) => (
                        <TableRow key={row.text.text + row.value}>
                          <Counter value={isTableVisible && row.value} type={row.value_type} />
                          <Text>
                            <RichText render={row.text.raw} />
                          </Text>
                        </TableRow>
                      ))}
                    </Table>
                  )}
                </ScrollTrigger>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Section>
  );
};

export default HomeS5;
