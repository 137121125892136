import 'swiper/swiper.scss';

import './HomeS4.scss';

import { isEmpty } from 'lodash';
import { RichText } from 'prismic-reactjs';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CTASquareLinkButton } from '../../../components/Button/Button';
import Col from '../../../components/Col/Col';
import Heading from '../../../components/Heading/Heading';
import PartnerBox from '../../../components/PartnerBox/PartnerBox';
import Row from '../../../components/Row/Row';
import ScrollTrigger from '../../../components/ScrollTrigger/ScrollTrigger';
import Section from '../../../components/Section/Section';

const setSlideOpacity = ({ isEnd, visibleSlidesIndexes, slides }) => {
  const parent = document.getElementsByClassName('home-s4__slider')[0];
  const allSlides = Array.from(slides);
  const visibleIndexes = Array.from(visibleSlidesIndexes);
  const elementWidth = slides[0].offsetWidth;
  const elementDomRect = parent.getBoundingClientRect();
  const elementMargin = parseInt(slides[0].style.marginRight, 10);
  const screenWidth = window.innerWidth;
  const halfVisibleElements =
    visibleIndexes.length - Math.floor((screenWidth - elementDomRect.left - elementMargin) / elementWidth);

  // reset
  slides.forEach((s) => {
    s.style.opacity = 1; // eslint-disable-line
  });

  if (!halfVisibleElements || halfVisibleElements <= 0) return;

  if (isEnd) {
    return;
  }

  Array.from(Array(halfVisibleElements)).forEach(() => {
    const index = visibleSlidesIndexes.pop();

    allSlides[index].style.opacity = 0.5;
  });
};

const HomeS4 = ({ headingRT = {}, linkText, linkTo, partners = [] }) => {
  if (!partners.length) {
    return null;
  }

  return (
    <Section className="home-s4">
      <Row>
        <Col xx={12} md={11} mdOffset={1}>
          {headingRT.text && (
            <ScrollTrigger>
              <Heading>
                <RichText render={headingRT.raw} />
              </Heading>
            </ScrollTrigger>
          )}

          <ScrollTrigger>
            <Swiper
              className="home-s4__slider"
              watchSlidesProgress
              watchSlidesVisibility
              slidesPerView="auto"
              slideToClickedSlide
              onResize={setSlideOpacity}
              onSwiper={setSlideOpacity}
              onTransitionStart={setSlideOpacity}
              breakpoints={{
                320: {
                  spaceBetween: 30,
                  slidesOffsetAfter: 30,
                },
                1024: {
                  spaceBetween: 50,
                  slidesOffsetAfter: 50,
                },
              }}
            >
              {partners.map(
                ({ partner: { document = {} } = {} }, idx) =>
                  !isEmpty(document) && (
                    <SwiperSlide key={document.id}>
                      <ScrollTrigger desktopSet={{ to: { delay: (idx % 3) / 2 } }}>
                        <PartnerBox
                          descRT={document.data.short_desc}
                          logoAlt={document.data.logo.alt}
                          logoUrl={document.data.logo.url}
                          name={document.data.name}
                        />
                      </ScrollTrigger>
                    </SwiperSlide>
                  )
              )}
            </Swiper>
            {linkText && <CTASquareLinkButton to={linkTo}>{linkText}</CTASquareLinkButton>}
          </ScrollTrigger>
        </Col>
      </Row>
    </Section>
  );
};

export default HomeS4;
