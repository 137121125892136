import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/navigation/navigation.scss';

import './HomeS3.scss';

import gsap from 'gsap';
import { RichText } from 'prismic-reactjs';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ListButton } from '../../../components/Button/Button';
import Col from '../../../components/Col/Col';
import Heading from '../../../components/Heading/Heading';
import Row from '../../../components/Row/Row';
import ScrollTrigger from '../../../components/ScrollTrigger/ScrollTrigger';
import Section from '../../../components/Section/Section';
import SlideContent from './SlideContent';

SwiperCore.use([Navigation, Pagination]);

const setSlideOpacity = (slide, duration = 0.4) => gsap.to(slide, { opacity: 1, duration });

const handleInit = ({ activeIndex, slides }) => setSlideOpacity(slides[activeIndex], 0);

const handleTransitionStart = ({ previousIndex, activeIndex, slides }) => {
  const prevSlide = slides[previousIndex];
  const activeSlide = slides[activeIndex];

  gsap.to(prevSlide, {
    opacity: 0,
    duration: 0.4,
    onComplete: () => {
      setSlideOpacity(activeSlide);
    },
  });
};

const handleSetTranslate = ({ slides }) => {
  const slidesArray = Object.values(slides);

  slidesArray.forEach((slide) => {
    const offset = slide.swiperSlideOffset;

    gsap.set(slide, {
      x: -offset,
      y: 0,
    });
  });
};

const HomeS3 = ({ headingRT = {}, slides = [] }) => {
  if (!slides.length) {
    return null;
  }

  return (
    <Section className="home-s3">
      <Row className="home-s3__inner">
        <Col xx={12} md={4} mdOffset={1} className="home-s3__left">
          <ScrollTrigger>
            {headingRT.text && (
              <Heading className="home-s3__heading">
                <RichText render={headingRT.raw} />
              </Heading>
            )}
          </ScrollTrigger>
          <ScrollTrigger>
            <div id="home-s3__pagination" />
          </ScrollTrigger>
        </Col>
        <Col xx={12} md={5} mdOffset={1} className="home-s3__sl">
          <ScrollTrigger desktopSet={{ to: { delay: 0.4 } }}>
            <Swiper
              className="home-s3__slider"
              virtualTranslate
              navigation={{
                prevEl: '.home-s3__slider-prev',
                nextEl: '.home-s3__slider-next',
                el: '.home-s3__slider-button',
              }}
              pagination={{
                clickable: true,
                el: '#home-s3__pagination',
                renderBullet: (index, className) => {
                  if (!slides[index]) return null;

                  return ReactDOMServer.renderToString(
                    <ListButton className={`button button--trans-pop ${className}`}>{slides[index].title}</ListButton>
                  );
                },
                crossFade: true,
              }}
              speed={800}
              watchSlidesProgress
              onSwiper={handleInit}
              onTransitionStart={handleTransitionStart}
              onSetTranslate={handleSetTranslate}
            >
              {slides.map((slide, index) => (
                <SwiperSlide
                  key={slide.title}
                  className={`home-s3__slider-slide home-s3__slider-slide--${slide.color}`}
                >
                  <SlideContent {...slide} headingRT={headingRT} index={index} slides={slides} />
                </SwiperSlide>
              ))}
            </Swiper>
          </ScrollTrigger>
        </Col>
      </Row>
    </Section>
  );
};

export default HomeS3;
