import './HomeS1.scss';

import { RichText } from 'prismic-reactjs';
import React from 'react';

import Col from '../../../components/Col/Col';
import Heading from '../../../components/Heading/Heading';
import Image from '../../../components/Image/Image';
import Row from '../../../components/Row/Row';
import ScrollTrigger from '../../../components/ScrollTrigger/ScrollTrigger';
import Section from '../../../components/Section/Section';

const HomeS1 = ({ headingRT = {}, headIMG = {}, scrollDownTargetId }) => {
  if (!headingRT.text && !headIMG.fluid) {
    return null;
  }

  return (
    <Section className="home-s1">
      <div id={scrollDownTargetId}>
        <Row>
          <Col md={7} mdOffset={4}>
            <ScrollTrigger>{headIMG.fluid && <Image {...headIMG} />}</ScrollTrigger>
            <ScrollTrigger>
              {headingRT.text && (
                <Heading>
                  <RichText render={headingRT.raw} />
                </Heading>
              )}
            </ScrollTrigger>
          </Col>
        </Row>
      </div>
    </Section>
  );
};

export default HomeS1;
