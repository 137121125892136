import './WeAreBad.scss';

import React from 'react';
import { FormattedMessage } from 'react-intl';

import Heading from '../Heading/Heading';

const WeAreBad = () => {
  return (
    <Heading className="we-are-bad">
      <h1>
        <strong>
          <FormattedMessage id="common.brand" />
        </strong>
      </h1>
    </Heading>
  );
};

export default WeAreBad;
