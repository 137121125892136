import './HomeS7.scss';

import { RichText } from 'prismic-reactjs';
import React from 'react';

import { CTACircleLinkButton } from '../../../components/Button/Button';
import Col from '../../../components/Col/Col';
import Heading from '../../../components/Heading/Heading';
import LinkImage from '../../../components/LinkImage/LinkImage';
import Row from '../../../components/Row/Row';
import ScrollTrigger from '../../../components/ScrollTrigger/ScrollTrigger';
import Section from '../../../components/Section/Section';
import Text from '../../../components/Text/Text';

const HomeS7 = ({ descRT = {}, headIMG = {}, headingRT = {}, linkText, linkTo }) => {
  if (!headIMG.fluid && !headingRT.text) {
    return null;
  }

  return (
    <Section className="home-s7">
      <Row>
        <Col xx={12} md={4} mdOffset={1}>
          <ScrollTrigger>
            {headingRT.text && (
              <Heading>
                <RichText render={headingRT.raw} />
              </Heading>
            )}
          </ScrollTrigger>
        </Col>
        <Col md={6}>
          <ScrollTrigger desktopSet={{ to: { delay: 0.4 } }}>
            {headIMG.fluid && <LinkImage imgProps={headIMG} to={linkTo} />}
          </ScrollTrigger>
          <ScrollTrigger>
            {descRT.text && (
              <Text>
                <RichText render={descRT.raw} />
              </Text>
            )}
            {linkText && <CTACircleLinkButton to={linkTo}>{linkText}</CTACircleLinkButton>}
          </ScrollTrigger>
        </Col>
      </Row>
    </Section>
  );
};

export default HomeS7;
