import cx from 'classnames';
import { RichText } from 'prismic-reactjs';
import React from 'react';

import Heading from '../../../components/Heading/Heading';
import Text from '../../../components/Text/Text';
import SwipeArrow from '../../../images/swipe-arrow.svg';

const SlideContent = ({ headingRT = {}, icon, index, slides, text, title }) => {
  return (
    <React.Fragment>
      <div>
        {headingRT.text && (
          <Heading>
            <RichText render={headingRT.raw} />
          </Heading>
        )}
        <Text tag="p" className="home-s3__slider-subheading">
          {title}
        </Text>
        <Text className="home-s3__slider-paragraph">
          <RichText render={text.raw} />
        </Text>
      </div>
      <div>
        {icon.fluid && <img className="home-s3__slider-image" alt={icon.alt} src={icon.fluid.src} />}
        <div className="home-s3__slider-bottom">
          <span>{`${index + 1}/${slides.length}`}</span>
          <div className="home-s3__slider-button">
            <button
              className={cx('home-s3__slider-prev home-s3__slider-arrow', {
                'home-s3__slider-arrow--hidden': index === 0,
              })}
              type="button"
            >
              <img className="rotate" alt="Swipe left arrow" src={SwipeArrow} />
            </button>
            <span>Swipe</span>
            <button
              className={cx('home-s3__slider-next home-s3__slider-arrow', {
                'home-s3__slider-arrow--hidden': index + 1 === slides.length,
              })}
              type="button"
            >
              <img alt="Swipe right arrow" src={SwipeArrow} />
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SlideContent;
