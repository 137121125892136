import './RollButLast.scss';

import gsap from 'gsap';
import { initial, last } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

const RollButLast = ({ children, start }) => {
  const rootElem = useRef(null);
  const [tl, setTl] = useState(null);

  /* Handle mounted, create new timeline */
  useEffect(() => {
    if (!rootElem) {
      return;
    }

    setTl(gsap.timeline());
  }, [rootElem]);

  /* Handle tl created, define animation */
  useEffect(() => {
    if (!tl) {
      return () => {};
    }

    tl.kill();

    const allRolls = Array.from(rootElem.current.children);
    const headRolls = initial(allRolls);
    const lastRoll = last(allRolls);

    // pause on start
    tl.pause();

    // keep
    tl.to({}, { delay: 0.1 });

    // fade in all rolls
    allRolls.forEach((roll) => {
      tl.fromTo(
        roll,
        {
          y: -15,
        },
        {
          delay: 0.15,
          duration: 0.3,
          ease: 'sine.out',
          opacity: 1,
          y: 0,
        }
      );
    });

    // keep
    tl.to({}, { delay: 3.5 });

    // fade out head rolls
    headRolls.forEach((roll) => {
      tl.to(roll, {
        duration: 0.15,
        opacity: 0,
        y: 20,
      });
    });

    // fade out last
    tl.to(lastRoll, {
      delay: 1.5,
      duration: 0.2,
      opacity: 0,
    });

    tl.repeat(-1);

    return () => {
      tl.kill();
    };
  }, [tl]);

  /* Handle start change, run/pause animation */
  useEffect(() => {
    if (!tl) {
      return;
    }

    if (!start) {
      tl.pause();
      return;
    }

    tl.play();
  }, [start, tl]);

  return (
    <div className="roll-but-last" ref={rootElem}>
      {children}
    </div>
  );
};

export default RollButLast;
