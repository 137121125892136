import './HomeS2.scss';

import { RichText } from 'prismic-reactjs';
import React from 'react';

import { CTACircleLinkButton } from '../../../components/Button/Button';
import Col from '../../../components/Col/Col';
import Heading from '../../../components/Heading/Heading';
import LinkImage from '../../../components/LinkImage/LinkImage';
import Row from '../../../components/Row/Row';
import ScrollTrigger from '../../../components/ScrollTrigger/ScrollTrigger';
import Section from '../../../components/Section/Section';
import Table from '../../../components/Table/Table';
import TableRow from '../../../components/Table/TableRow';
import Text from '../../../components/Text/Text';

const HomeS2 = ({ headIMG = {}, headingRT = {}, linkText, linkTo, tableHeaderRT = {}, tableRows = [] }) => {
  if (!headIMG.fluid && !headingRT.text && !tableRows.length) {
    return null;
  }

  return (
    <Section className="home-s2">
      <Row>
        <Col xx={12} md={4} mdOffset={1}>
          <ScrollTrigger>{headIMG.fluid && <LinkImage imgProps={headIMG} to={linkTo} />}</ScrollTrigger>
        </Col>
        <Col xx={12} md={5} mdOffset={1}>
          <ScrollTrigger>
            {headingRT.text && (
              <Heading>
                <RichText render={headingRT.raw} />
              </Heading>
            )}
          </ScrollTrigger>
          <ScrollTrigger>
            {tableRows.length > 0 && (
              <Table>
                {tableHeaderRT.text && (
                  <TableRow header>
                    <Text>
                      <RichText render={tableHeaderRT.raw} />
                    </Text>
                  </TableRow>
                )}
                {tableRows.map((row) => (
                  <TableRow key={row.text.text}>
                    <Text>
                      <RichText render={row.text.raw} />
                    </Text>
                  </TableRow>
                ))}
              </Table>
            )}
            {linkText && <CTACircleLinkButton to={linkTo}>{linkText}</CTACircleLinkButton>}
          </ScrollTrigger>
        </Col>
      </Row>
    </Section>
  );
};

export default HomeS2;
