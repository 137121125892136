import './ScrollDown.scss';

import gsap from 'gsap';
import React from 'react';

import IconSrc from '../../images/arrow-down.svg';

const ScrollDown = ({ targetId }) => {
  if (!targetId) {
    return null;
  }

  const handleOnClick = () => {
    gsap.to(window, { duration: 0.6, scrollTo: { y: `#${targetId}`, offsetY: 30 } });
  };

  return (
    <button className="scroll-down" type="button" onClick={handleOnClick}>
      <img alt="scroll down" src={IconSrc} />
    </button>
  );
};

export default ScrollDown;
